import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Stack,
  Chip,
  Link,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';

// utils
// component
import SearchContext from '../../contexts/SearchContext';
import PageContext from '../../contexts/PageContext';
import { fieldsService } from '../../services';
import { SearchOption } from '../../types/SearchOption';
// ----------------------------------------------------------------------

const StyledSearchbar = styled('div')(({ theme }) => ({
  // width: '100%',
  // maxWidth: '40vw',
  [theme.breakpoints.up('md')]: {},
  // backgroundColor: 'red'
}));

// ----------------------------------------------------------------------

export default function SearchbarTvQuickLinks() {

  const intl = useIntl();
  const theme = useTheme();
  const pageContext = useContext(PageContext);
  const { user } = pageContext;
  const location = useLocation();

  const searchContext = useContext(SearchContext);

  // useEffect(() => {
  //   let cancelled = false;
  //   return () => {
  //     cancelled = true;
  //   };
  // }, []);


  const HeaderItem = ({ label, value }) => (
    <Typography variant="h6"
      sx={{ px: 3 }}
      key={value}>
      <Link
        underline='none'
        // sx={{ color: 'text.primary' }}
        onClick={() => searchContext.simplifiedSearch({ byKey: value })}>{label}</Link>
    </Typography>
  );

  return (
    <>
      <StyledSearchbar>
        <Stack direction={'row'}>
          {
            [
              { label: 'Km 0', value: 'km0' },
              { label: 'Auction', value: 'auctions' },
              { label: 'Ex-Leasing', value: 'exLeasing' },
              { label: 'Used certified', value: 'usedCertified' },
              // {label: 'Info', value: ''},
            ].map(({ label, value }) => (
              // <FocusableHeaderItem label={label} />
              <HeaderItem label={label} value={value} />
            ))
          }
        </Stack>
      </StyledSearchbar>
    </>
  );
}
