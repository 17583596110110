import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../components/iconify';
import SearchContext from '../../contexts/SearchContext';
import { DEFAULT_SORT_ORDER } from '../../config/config';

// ----------------------------------------------------------------------

// sortField:(desc|asc). Multiple sorting criteria should be separated by commas (,)
const SORT_BY_OPTIONS = [
  // { value: 'featured', label: 'Featured' },
  // { value: 'newest', label: 'Newest' },
  { value: 'price.selling:asc', label: 'Price: Low-High', key: 'cars.sortBy.price.selling.asc' },
  { value: 'price.selling:desc', label: 'Price: High-Low', key: 'cars.sortBy.price.selling.desc' },
  { value: 'firstRegistrationAsDate:desc', label: 'Newest', key: 'cars.sortBy.firstRegistrationAsDate.desc' },
  { value: 'firstRegistrationAsDate:asc', label: 'Oldest', key: 'cars.sortBy.firstRegistrationAsDate.asc' },
  { value: 'mileage:asc', label: 'Mileage: Low-High', key: 'cars.sortBy.mileage.asc' },
  { value: 'mileage:desc', label: 'Mileage: High-Low', key: 'cars.sortBy.mileage.desc' },
  { value: 'enginePowerHP:desc', label: 'Power: High-Low', key: 'cars.sortBy.enginePowerHP.desc' },
  { value: 'enginePowerHP:asc', label: 'Power: Low-High', key: 'cars.sortBy.enginePowerHP.asc' },
];

export default function CarsSort() {
  const searchContext = useContext(SearchContext);

  const [open, setOpen] = useState(null);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(SORT_BY_OPTIONS
    .map(({ value }, index) => ({ value, index }))
    .filter(({ value }) => value === DEFAULT_SORT_ORDER)
    .map(({ index }) => index)
    .shift() || 0);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = (event, index) => {
    const sortOrder = [SORT_BY_OPTIONS[index].value];
    setSelectedIndex(index);
    // console.log(sortOrder)

    searchContext.searchWithSortOrder(sortOrder);
    // searchContext.setSortOrder(sortOrder)
    // searchContext.search({ forcedSortOrder: sortOrder })

    handleClose();
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <FormattedMessage id="cars.sortBy.title" />:
        <Typography component="span" variant="subtitle2" sx={{ pl:1, color: 'text.secondary' }}>
          {/* {SORT_BY_OPTIONS[selectedIndex].label} */}
          <FormattedMessage id={SORT_BY_OPTIONS[selectedIndex].key} />
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={(searchContext.sortOrder || []).includes(option.value)}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{ typography: 'body2' }}
          >
            {/* {option.label}
             */}
            <FormattedMessage id={option.key} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
