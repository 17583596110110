import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/material/styles';

// @mui
import {
    Box, Card, CardMedia,
    Paper
} from '@mui/material';



const StyledCarsPageImg = styled('img')({
    top: 0,
    width: '100%',
    // width: '90vw',
    // width: '400px',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});
const StyledCarPageImg = styled('img')({

    // width: '100%',
    // // width: '90vw',
    // // width: '400px',
    // height: '100%',
    // objectFit: 'cover',
});

// FIXME: reduce complexity
export default function CarCarouselImages({ car,
    startIndex,
    isFullScreen = false,
    isCarsPage = false,
    onClickHandler,
    numberOfImagesAllowed = 0,
    sx = {} }
) {

    const useFullHeight = isCarsPage || isFullScreen

    const images = isCarsPage ? car.images : car.allImages
    const allowedImages = numberOfImagesAllowed <= 0 ? images : images.slice(0, numberOfImagesAllowed)
    const interval = isFullScreen ? 25000 : 12500
    const animation = isFullScreen ? 'slide' : 'fade'
    const autoPlay = !isCarsPage
    return (
        <Carousel
            key={`${car.vin}-${isFullScreen}`}
            index={startIndex || undefined}
            sx={sx}
            interval={interval}
            animation={animation}
            autoPlay={autoPlay}
            indicators={false}
            navButtonsAlwaysVisible={!isCarsPage}
            navButtonsAlwaysInvisible={isCarsPage}
            navButtonsProps={{
                style: {
                    backgroundColor: 'primary',
                    opacity: 0.3,
                },
            }}>
            {allowedImages.map((image, index) => {
                const bgImage = `url(${image})`;
                return isFullScreen
                    ? (<Box
                        key={image}
                        sx={{
                            width: '100vw',
                            height: '100vh',
                            // backgroundColor: 'yellow',
                            backgroundImage: bgImage,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                        onClick={(e) => onClickHandler(index, isFullScreen)}
                    />)
                    : (isCarsPage
                        ? (
                            <Box
                                key={image}
                                onClick={(e) => onClickHandler(index, isFullScreen)}>
                                <StyledCarsPageImg src={image} alt={car.vin} />
                            </Box>
                        ) : (
                            <Paper
                                key={index}
                                sx={{
                                    height: {
                                        xs: '17.5rem',
                                        sm: '25rem',
                                        md: '37.5rem',
                                        lg: '37.5rem',
                                        xl: '42rem'
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                }}
                                onClick={(e) => onClickHandler(index, isFullScreen)}
                            >
                                <Box sx={{
                                    width: 'auto',
                                    height: 'fit-content',
                                }}>
                                    <StyledCarPageImg
                                        src={image}
                                        alt={`Image # ${index}`}
                                        sx={{
                                            height: 'auto', // Image height matches container height
                                            // width: 'auto', // Image width adjusts to fit the container
                                            objectFit: 'cover', // Ensures the image covers the container while maintaining aspect ratio
                                            objectPosition: 'center'
                                        }}
                                    />
                                </Box>
                            </Paper>
                        )
                    )
            })}
        </Carousel>
    );
};