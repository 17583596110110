import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@emotion/react';
import { Box, Snackbar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import HeaderWeb from './header/HeaderWeb';
import FooterWeb from './footer/FooterWeb';
import { getUITarget } from '../../services/asyncStorage.service';
import SearchContext from '../../contexts/SearchContext';
import PageContext from '../../contexts/PageContext';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '20vh',
  minHeight: '70%',
  // height: '40vh',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    // minHeight: '70vh',
    // paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const Secondary = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '20vh',
  // minHeight: '30vh',
  minHeight: '70%',
  // height: '30vh',
  // backgroundColor: 'turquoise',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(3),
  // paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    // minHeight: '70vh',
    // paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// const StyledHeader = styled('header')(({ theme }) => ({
//   top: 0,
//   left: 0,
//   lineHeight: 0,
//   width: '100%',
//   position: 'absolute',
//   padding: theme.spacing(3, 3, 0),
//   [theme.breakpoints.up('sm')]: {
//     padding: theme.spacing(5, 5, 0),
//   },
// }));

// const ProductHeroLayout = styled('section')(({ theme }) => ({
//   color: theme.palette.common.white,
//   position: 'relative',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     height: '80vh',
//     minHeight: 500,
//     maxHeight: 1300,
//   },
// }));

// ----------------------------------------------------------------------

export default function SimpleLayoutWeb(props) {
  const { resellerId } = useParams();
  const theme = useTheme();
  const searchContext = useContext(SearchContext);
  const pageContext = useContext(PageContext);

  const [open, setOpen] = useState(false)
  const snackbarState = useMemo(() => pageContext.snackbarState, [pageContext.snackbarState])

  return (
    <>
      {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
      <HeaderWeb
        onOpenNav={() => setOpen(true)}
        onChangeLanguage={props.onChangeLanguage}
        currentLanguage={props.currentLanguage}
      />
      <Main>
        <Box xs={{ width: '100%' }} >
          <Outlet />
        </Box>
      </Main>

      {/* 
        <Secondary>
          <Box xs={{ width: '100%' }} >
            <Outlet />
          </Box>
        </Secondary>
      )} */}
      <FooterWeb />
    </>
  );
}
