import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Link } from '@mui/material';
// mocks_
// import account from '../../../_mock/account';
import { randomAvatar } from '../../../utils/randomAvatar';
import PageContext from '../../../contexts/PageContext';
import Label from '../../../components/label/Label';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:person-fill',
    destination: '/',
  },
  {
    label: 'User Settings',
    icon: 'eva:settings-2-fill',
    destination: '/user-settings',
  },
];


const RESELLER_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:person-fill',
    destination: '/',
  },
  {
    label: 'Company Profile',
    icon: 'eva:person-fill',
    destination: '/company-profile',
  },
  {
    label: 'User Settings',
    icon: 'eva:settings-2-fill',
    destination: '/user-settings',
  },
];

const ADMIN_MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:person-fill',
    destination: '/',
  },
  {
    label: 'Users',
    icon: 'eva:person-fill',
    destination: '/admin/users',
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover({ user }) {
  const navigate = useNavigate();
  const pageContext = useContext(PageContext);

  const [open, setOpen] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(MENU_OPTIONS);

  useEffect(() => {
    if(user.roles.includes('admin')) {
      setCurrentOptions(ADMIN_MENU_OPTIONS)
    } else if(user.roles.includes('reseller')) {
      setCurrentOptions(RESELLER_MENU_OPTIONS)
    }
  }, [])

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (destination) => {
    setOpen(false);
    // if (destination) {
    //   console.log('destination', destination)
    //   navigate(destination);
    // }
  };

  const handleLogout = async () => {
    await pageContext.logout();
    handleClose();
    navigate('/');
  };

  const avatarUrl = user && user.photo ? user.photo : randomAvatar(12);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={avatarUrl} alt="photoURL" />
      </IconButton>

      {user ? (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user.email}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user.roles
                .filter(role => !['user'].includes(role))
                .map(role => <Label key={role}>{role}</Label>)}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            {currentOptions.map(({ label, destination }) => (
              <MenuItem key={label} component={Link} href={destination}>
                {label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={() => handleLogout()} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Popover>
      ) : null}
    </>
  );
}
