import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useEffect, useMemo, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  DialogActions,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/user';
// services
import { usersService } from '../services/index';


// ----------------------------------------------------------------------
const DialogContentType = {
  changeRole: 'changeRole'
}

const TABLE_HEAD = [
  { id: 'displayName', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'authProvider', label: 'authProvider', alignRight: false },
  { id: 'roles', label: 'Roles', alignRight: false },
  { id: 'isEmailVerified', label: 'Verified', alignRight: false },
  { id: '' },
];
// const rowsPerPageOptions = [5, 10, 15]
const rowsPerPageOptions = [25, 50, 100]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const intl = useIntl();

  const [users, setUsers] = useState([]);

  const [emptyRows, setEmptyRows] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isNotFound, setIsNotFound] = useState(null);

  const [openPopper, setOpenPopper] = useState(null);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('email');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [openDialog, setOpenDialog] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [dialogContentType, setDialogContentType] = useState(DialogContentType.changeRole);

  const [rolesState, setRolesState] = React.useState({
    user: false,
    reseller: false,
    admin: false,
  });


  const loadUserList = async () => {
    usersService.getUsers(page, rowsPerPage, ['email'])
      .then(response => {
        setUsers(response.results)
        setTotalResults(response.totalResults)
      })
  }

  useEffect(() => {
    loadUserList()
  }, [])

  useEffect(() => {
    loadUserList()
  }, [page, rowsPerPage])

  useEffect(() => {
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalResults) : 0;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalResults) : 0;
    setEmptyRows(emptyRows)
    const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
    setFilteredUsers(filteredUsers)
    const isNotFound = !filteredUsers.length && !!filterName;
    setIsNotFound(isNotFound)
  }, [users]) // totalResults changes togheter as users

  useEffect(() => {
    const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
    setFilteredUsers(filteredUsers)
    const isNotFound = !filteredUsers.length && !!filterName;
    setIsNotFound(isNotFound)
  }, [filterName])

  const currentPageFilteredUsers = useMemo(() => {
    return filteredUsers // .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
  }, [filteredUsers, page, rowsPerPage])

  const handleOpenMenu = (event, selectedUser, roles) => {
    console.log('handleOpenMenu.selectedUser', selectedUser)
    setOpenPopper(event.currentTarget);
    setSelectedUserId(selectedUser)
    setRolesState({
      user: roles.includes('user'),
      reseller: roles.includes('reseller'),
      admin: roles.includes('admin'),
    })
  };

  const handleCloseMenu = () => {
    setOpenPopper(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const openDialogChangeRole = () => {
    setDialogContentType(DialogContentType.changeRole)
    setOpenDialog(true);
    setOpenPopper(false)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenPopper(false)
  };

  const handleRoleChange = (e) => {
    setRolesState({
      ...rolesState,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.user' })}
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    currentPageFilteredUsers
                      // filteredUsers
                      //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { id, displayName, roles, email, authProvider, avatarUrl, isEmailVerified } = row;
                        const selectedUser = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={displayName} src={avatarUrl} />
                                <Typography variant="subtitle2" noWrap>
                                  {displayName}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{email}</TableCell>

                            <TableCell align="left">{authProvider}</TableCell>

                            <TableCell align="left">
                              {roles
                                // .filter(role => !['user'].includes(role))
                                .map(role => <Label sx={{ mr: .125 }} key={role}>{role}</Label>)
                              }
                            </TableCell>

                            <TableCell align="left">{isEmailVerified ? 'Yes' : 'No'}</TableCell>

                            <TableCell align="left">
                              {/* <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label> */}
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={e => handleOpenMenu(e, id, roles || [])}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for {' '}
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalResults}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(openPopper)}
        anchorEl={openPopper}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={() => openDialogChangeRole()}>
          <Iconify icon={'eva:person-done-outline'} sx={{ mr: 2 }} />
          Change role
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Dialog title='Change Roles'
        open={Boolean(openDialog)}
        onClose={handleCloseDialog}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            // console.log(selectedUserId);
            // console.log(rolesState);
            const roles = Object.entries(rolesState)
              .filter(([key, value]) => value)
              .map(([key, value]) => key)
            // console.log(roles);
            handleCloseDialog();
            usersService.patchUserRoles(selectedUserId, { roles }).then(() => {
              loadUserList()
            })
          },
        }}
      >
        <DialogTitle>Change Roles</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Whith this action you're going to change the user role.
            You maight choose between the follows:
          </DialogContentText>
          <FormGroup>
            <FormControlLabel disabled
              control={<Checkbox value={'user'} defaultChecked />}
              label="User"
              name="user"
              checked={rolesState.user}
              onChange={handleRoleChange}
            />
            <FormControlLabel
              control={<Checkbox value={'reseller'} />}
              label="Reseller"
              name="reseller"
              checked={rolesState.reseller}
              onChange={handleRoleChange}
            />
            {/* <FormControlLabel
              control={<Checkbox value={'admin'} />}
              label="Admin"
              name="admin"
              checked={rolesState.admin}
              onChange={handleRoleChange}
            /> */}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button type="submit">Change</Button>
        </DialogActions>
      </Dialog >
    </>
  );
}
