import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Stack,
    Button,
    Dialog,
    IconButton,
    Typography,
    Skeleton,
    useMediaQuery,
    DialogContent,
    DialogActions,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// components
import SearchContext from '../../contexts/SearchContext';

export default function ShowAllButton() {
    const searchContext = useContext(SearchContext);
    const disabled = true
    const handleSearch = async () => {
        await searchContext.search();
    };
    if (disabled) {
        return null
    }
    return (searchContext.isFirstSearchExecuted ? null : <LoadingButton
        variant="outlined"
        size='large'
        color="primary"
        loadingPosition="start"
        loading={searchContext.isLoading}
        onClick={e => handleSearch()}
        startIcon={<SearchIcon />}
    >
        {/* {searchContext.isFirstSearchExecuted ? (
            <FormattedMessage
                id="cars.searchbar.numberOfResults"
                values={{
                    numberOfResults: searchContext.displayTotalResults,
                }}
            />
        ) : ( */}
        <FormattedMessage id="cars.searchbar.searchAll" />
        {/* )} */}
    </LoadingButton>
    )
}