import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
// import useNavigator from "react-browser-navigator";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

// routes
import { Router, RouterTv } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import Italian from './lang/it.json';
import French from './lang/fr.json';
import German from './lang/de.json';
import Dutch from './lang/nl.json';
import English from './lang/en.json';
import Commons from './lang/commons.json';
import { PageContextProvider } from './contexts/PageContext';
import { SearchContextProvider } from './contexts/SearchContext';
import { REACT_APP_TARGET_PLATFORM } from './config/config';

export default function App() {
  const isTv = REACT_APP_TARGET_PLATFORM === 'tv';
  const isMobile = REACT_APP_TARGET_PLATFORM === 'mobile';

  // const { language } = useNavigator();

  const [language, setLanguage] = useState('en');

  const [i18nConfig, setI18nConfig] = useState({
    locale: 'en',
    messages: { ...Commons, ...English },
  });

  const loadLocaleData = (locale) => {
    switch (locale) {
      case 'it':
        return { ...Commons, ...Italian };
      case 'fr':
        return { ...Commons, ...French };
      case 'de':
        return { ...Commons, ...German };
      case 'nl':
        return { ...Commons, ...Dutch };
      default:
        return { ...Commons, ...English };
    }
  };

  const onChangeLanguage = (lang) => {
    // console.log('onChangeLanguage', lang)
    setI18nConfig({
      locale: lang,
      messages: loadLocaleData(lang),
    });
  };

  useEffect(() => {
    if (language) {
      // console.log("language", language);
      const lang = language.split('-').shift()
      // console.log("lang", lang);
      onChangeLanguage(lang)
    }
  }, [language])

  useEffect(() => {
    // Retrieve the user's preferred language
    const userLanguage = navigator.language || navigator.userLanguage;
    setLanguage(userLanguage);
  }, []);


  return (
    <HelmetProvider>
      <BrowserRouter>
        <SnackbarProvider>
           {/*  NGNIX possible solution using an header 
              location ~* ^/([a-zA-Z0-9_-]+)(/|$) {
                set $tenant $1;
                proxy_set_header X-Tenant $tenant;
                proxy_pass http://application_backend;
            } */}
          {/* <ResellerRouter> to set the reseller in react context */}
            <PageContextProvider>
              <ThemeProvider>
                <SearchContextProvider>
                  <IntlProvider key={i18nConfig.locale} locale={i18nConfig.locale} messages={i18nConfig.messages}>
                    {isTv ? (
                      <RouterTv onChangeLanguage={onChangeLanguage} currentLanguage={i18nConfig.locale} />
                    ) : (
                      <>
                        <ScrollToTop />
                        <Router onChangeLanguage={onChangeLanguage} currentLanguage={i18nConfig.locale} isMobile={isMobile} />
                      </>
                    )}
                  </IntlProvider>
                </SearchContextProvider>
              </ThemeProvider>
            </PageContextProvider>
          {/* </ResellerRouter> */}
        </SnackbarProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
