import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, useMediaQuery, Card, Skeleton } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
// utils
// components
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import PageContext from '../../../contexts/PageContext';
import SearchContext from '../../../contexts/SearchContext';
import HeaderSection from '../../../components/header-section/HeaderSection';

import navConfig from '../../../config/navConfig';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from '../../../config/config';
import { Logo } from '../../../components/logo';

// ----------------------------------------------------------------------

// const NAV_WIDTH = 280;

// const HEADER_MOBILE = 64;

// const HEADER_DESKTOP = 92;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  // ...bgBlur({ color: theme.palette.background.default }),
  // backgroundColor: 'transparent',
  // backgroundColor: 'white',
  backgroundColor: 'rgb(0,0,0,.5)',
  // opacity: .5,
  top: 0,
  height: APP_BAR_MOBILE,
  position: 'absolute',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    height: APP_BAR_DESKTOP,
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  display: 'flex',
  alignContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

const CarsHeroSection = styled(Card)(({ theme, small }) => ({
  // ({ isTv, small, ...other }) {

  // mt: small ? 0 : 3,
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: small ? APP_BAR_MOBILE : '60vh',
  [theme.breakpoints.up('lg')]: {
    height: small ? APP_BAR_DESKTOP : '60vh',
    // padding: theme.spacing(0, 5),
  },
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  // opacity: .7,
  // backgroundImage: 'url(/assets/images/hero/carsHero2.jpg)',
  // backgroundSize: 'cover', // auto|length|cover|contain|initial|inherit;
  opacity: small ? 0.8 : 0.9,
  // backgroundImage: 'url(/assets/images/hero/hero-section-1.jpg)',
  // backgroundImage: 'url(/assets/images/hero/hero-section-2.jpg)',
  backgroundImage: 'url(/assets/images/hero/hero-section-3.webp)',
  // backgroundImage: 'url(/assets/images/hero/cars-hero.jpg)',
  backgroundSize: 'cover', // auto|length|cover|contain|initial|inherit;
}));

HeaderWeb.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function HeaderWeb({ onOpenNav, onChangeLanguage, currentLanguage }) {
  const theme = useTheme();
  const location = useLocation();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const pageContext = useContext(PageContext);
  const searchContext = useContext(SearchContext);
  const [isCarPage, setIsCarPage] = useState(false);
  const [user, setUser] = useState();
  const userRoles = user && user.roles ? user.roles : [];

  useEffect(() => {
    const pathAsArray = location.pathname.split('/').filter(x => !!x)
    const lastPathPart = pathAsArray.pop()
    const previousPathPart = pathAsArray.pop()
    setIsCarPage(['car'].includes(previousPathPart))
  }, [location])

  useEffect(() => {
    const { user } = pageContext;
    setUser(user);
  }, [pageContext.user]);

  return (
    <div>
      <CarsHeroSection small />

      <StyledAppBar position={'sticky'} elevation={0}>
        {/* <StyledAppBar position={isSmDown ? 'sticky' : 'fixed'} elevation={0}> */}
        <StyledToolbar>
          <Box>
            <Logo />
          </Box>

          {/* <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton> */}

          <Box sx={{ flexGrow: 1 }} />

          {/* <HeaderSection data={navConfig} userRoles={userRoles} />

          <Box sx={{ flexGrow: 1 }} /> */}

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            flexShrink={0}
          >
            <LanguagePopover onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />
            {/* TODO enable in future personal notifications */}
            {/* <NotificationsPopover /> */}

            {pageContext && pageContext.isPageContextLoaded ? (
              <>
                {user ? (
                  <AccountPopover user={user} />
                ) : (
                  <Button sx={{flexShrink: 0}} href="/login" variant="contained" startIcon={<LoginIcon />}>
                    Log in
                  </Button>
                )}
              </>
            ) : <Skeleton variant="circular" />}
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
    </div>
  );
}
