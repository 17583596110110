import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Typography, Button, ButtonGroup } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink, hideLinks, sx, ...other }, ref) => {
  const intl = useIntl();

  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box>
      <Box
        ref={ref}
        component="div"
        sx={{
          height: 40,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'flex-start',
          ...sx,
          // backgroundColor: 'red',
        }}
        {...other}
      >
        <Box display={{ xs: 'block', sm: 'none' }} flexGrow={0}>
          <img src="/assets/logo-white.png" alt="logo" width="40" />
        </Box>
        <Box display={{ xs: 'none', sm: 'block', md:'none' }} flexGrow={0}>
          <img src="/assets/logo-white.png" alt="logo" width="70" />
        </Box>
        <Box display={{ xs: 'none', md: 'block' }} flexGrow={0}>
          <img src="/assets/logo-white.png" alt="logo" height="40" />
        </Box>

        <Stack direction={'column'} justifyContent={'center'} sx={{ px: 1, flexGrow: 0 }}>
          <Typography component="span" variant="h4" color={'white'} >
            <FormattedMessage id="application.name" />
          </Typography>
          {hideLinks ? null : (
            <>
              <Stack direction={'row'} justifyContent={'center'} alignItems={'baseline'}>
                <Typography variant='caption' color='primary'>
                  <Link
                    size='small'
                    underline="none"
                    disabled
                    variant="text" href={intl.formatMessage({ id: "header.logo.web.link" })}
                    onClick={e => e.stopPropagation()}
                    sx={{ color: '#FFF', border: "1px solid transparent", textTransform: 'uppercase' }}>
                    <b><FormattedMessage id="header.logo.web.value" /></b>
                  </Link>
                </Typography>
                <Typography variant='caption' color='text.disabled' sx={{ pl: 1 }}>
                  <Link size='small'
                    underline="none"
                    variant="text"
                    href={intl.formatMessage({ id: "header.logo.tv.link" })}
                    onClick={e => e.stopPropagation()} sx={{ color: '#FFF', border: "1px solid transparent", textTransform: 'uppercase' }}>
                    <FormattedMessage id="header.logo.tv.value" />
                  </Link>
                </Typography>
              </Stack>
            </>
          )}
          <Box flexGrow={1} />
        </Stack>
      </Box>
    </Box >
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
