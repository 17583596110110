import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
// @mui
import {
  Typography,
  CardHeader,
  ListItemButton,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import carService from '../../services/cars.service';
// ----------------------------------------------------------------------

DocumentsSection.propTypes = {
  carId: PropTypes.string,
  documents: PropTypes.array,
  showHeader: PropTypes.bool,
  useCardHeader: PropTypes.bool,
};

export default function DocumentsSection({ carId, documents, useCardHeader = false, showHeader = false }) {
  const intl = useIntl();

  const doDownload = async (carId, docId, docName) => {
    // const link = document.createElement("a");
    // link.download = `download.txt`;
    // link.href = "./download.txt";
    // link.click();
    carService.getCarDocument(carId, docId).then(async (res) => {
      const data = await res.blob();
      console.log(res);
      console.log(data);
      // file object
      const file = new Blob([data], { type: 'application/pdf' });
      // anchor link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = docName || `${docId}.pdf`;
      // simulate link click
      document.body.appendChild(link); // Required for this to work in FireFox
      link.click();
    });
  };

  return (
    <>
      {' '}
      {documents && documents.length > 0 ? (
        <>
          {showHeader ? (
            <>
              {useCardHeader ? (
                <CardHeader title={intl.formatMessage({ id: 'car.section.title.documents' })} />
              ) : (
                <Typography variant="h5">
                  <FormattedMessage id="car.section.title.documents" />
                </Typography>
              )}
            </>
          ) : null}
          {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100vh' }}>
                    {documents.map(document => (
                        <Box>
                            <Document document={document} />
                        </Box>
                    ))}
                </Box> */}
          <List dense sx={{ width: '100%', maxWidth: 360 }}>
            <>
              {documents.map((doc, index) => (
                <ListItemButton key={index} onClick={(e) => doDownload(carId, doc._id, doc.name)}>
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={doc.name} />
                </ListItemButton>
              ))}
            </>
          </List>
        </>
      ) : null}
    </>
  );
}
