import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  Skeleton,
  useMediaQuery,
  DialogContent,
  DialogActions,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
// components
import Iconify from '../../components/iconify';
import SearchContext from '../../contexts/SearchContext';


import CarsFilterContent from './CarsFilterContent';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];

// ----------------------------------------------------------------------

CarsFilterDialog.propTypes = {
  // openFilter: PropTypes.bool,
  // handleOpenFilter: PropTypes.func,
  // handleCloseFilter: PropTypes.func,
  hideIcon: PropTypes.bool,
  hideButton: PropTypes.bool,
};

export default function CarsFilterDialog({ hideButton, hideIcon }) {
  const theme = useTheme();
  const searchContext = useContext(SearchContext);

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    // console.log('carsPageWeb.handleCloseFilter called');
    setOpenFilter(false);
  };

  const searchAndCloseFilter = async () => {
    await searchContext.search();
    handleCloseFilter();
  };

  return (
    <>
      {hideButton ? (
        <IconButton
          color="primary"
          onClick={handleOpenFilter}>
          <TuneIcon />
        </IconButton>
      ) : (
        <Button
          disableRipple
          color="primary"
          variant="outlined"
          onClick={handleOpenFilter}>
          <TuneIcon />
        </Button>
      )}

      <Dialog
        // open={true}
        open={openFilter}
        onClose={searchAndCloseFilter}
        // keepMounted
        fullWidth
      // TransitionComponent={Transition}
      >
        <DialogContent>
          {/* <DialogTitle xs={{
            backgroundColor: 'yellow',
          }}> */}
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              // backgroundColor: 'yellow',
              width: '100%',
              // px: 1,
              // py: 2,
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="h5" sx={{ flexGrow: 1, flexShrink: 1 }} component={'div'}>
              <FormattedMessage id="cars.searchbar.filter.title" />
            </Typography>
            <IconButton onClick={searchAndCloseFilter} sx={{ flexGrow: 0, flexShrink: 0 }}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
          {/* </DialogTitle> */}

          <CarsFilterContent />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={searchAndCloseFilter} sx={{ flexGrow: 0, flexShrink: 0 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>

          <LoadingButton
            variant="contained"
            color="secondary"
            loadingPosition="start"
            loading={searchContext.isLoading}
            onClick={e => searchAndCloseFilter()}
            startIcon={<SearchIcon />}
          >
            {searchContext.isFirstSearchExecuted ? (
              <FormattedMessage
                id="cars.searchbar.numberOfResults"
                values={{
                  numberOfResults: searchContext.displayTotalResults,
                }}
              />
            ) : (
              <FormattedMessage id="cars.searchbar.searchAll"/>
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
