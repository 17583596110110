const config = require('../config/config');
const { get, post, patch } = require('../utils/fetch');

const { BE_URL: beURL } = config;

const getUsers = async (page, size, sortOptions) => {
  const sortOptionsUrlParam = sortOptions ? `&sortBy=${sortOptions.join(',')}` : ``;
  return get(`${beURL}/users?page=${page+1}&limit=${size}${sortOptionsUrlParam}`);
}

const patchUserRoles = async (id, roles) => patch(`${beURL}/users/${id}`, roles);

// const companyProfile = async (email, values) => post(`${beURL}/reseller/${email}/company-profile`, values);

module.exports = {
  // companyProfile,
  getUsers,
  patchUserRoles
};
