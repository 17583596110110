// REFRESH TOKEN
export const storeRefreshToken = (token) => {
  localStorage.setItem('@refresh_token', token);
};
export const getRefreshToken = () => localStorage.getItem('@refresh_token');

// ACCESS TOKEN
export const getAccessToken = () => localStorage.getItem('@access_token');
export const storeAccessToken = (token) => {
  localStorage.setItem('@access_token', token);
};

export const removeTokens = () => {
  localStorage.removeItem('@refresh_token');
  localStorage.removeItem('@access_token');
};

// USER INFO

export const storeUserInfo = (user) => {
  localStorage.setItem('@user', JSON.stringify(user));
};
export const getUserInfo = () => JSON.parse(localStorage.getItem('@user'));
export const removeUserInfo = () => localStorage.removeItem('@user');

// UI TARGET

export const storeUITarget = async (targetName) => {
  localStorage.setItem('@ui_target', targetName);
};
export const getUITarget = async () => localStorage.getItem('@ui_target');

// RETURN TO
export const getReturnToFromStorage = () => localStorage.getItem('@return_to');
export const storeReturnTo = (uri) => {
  localStorage.setItem('@return_to', uri);
};
export const removeReturnTo = (uri) => {
  localStorage.removeItem('@return_to', uri);
};